import { compose, find, pathOr, propEq } from 'ramda'
import React from 'react'
import { ICtrlerType, ICtrlerTypeColumns } from './controller.type'

/**
 *
 * @param data : {data: any[]}
 * @returns
 */
const useHandleControllerResData = (data: any) => {
  const convertResData = React.useMemo(() => {
    const _dataSource = pathOr<ICtrlerType[]>([], ['data'], data).map(type => ({
      ...type,
      columns:
        type.columns?.length > 0
          ? type.columns.map((column: any) => ({
              ...column,
              required: !column.opt,
            }))
          : [],
    }))

    const getColumnsByType = (type: string) => {
      if (!type) {
        return []
      }

      const columns: ICtrlerTypeColumns[] = compose<any, any, any>(
        pathOr([], ['columns']),
        find(propEq('ctrlerType', type))
      )(_dataSource)
      return columns
    }

    return {
      getColumnsByType,
      dataSource: _dataSource,
    }
  }, [data])

  return {
    getColumnsByType: convertResData.getColumnsByType,
    dataSource: convertResData.dataSource,
  }
}

export default useHandleControllerResData
